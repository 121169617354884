<template>
  <div class="top-box">
    <span class="inline"></span>
    <span class="text">标签管理</span>
  </div>
  <a-tabs v-model:activeKey="activeKey" class="box-shadow my-box">
    <a-tab-pane :key="index" :tab="list.tag_name" v-for="(list, index) in tagList">
      <div class="list">
        <div v-for="(item) in list.tag_list" :key="item.id">
          <a-input
            v-if="item.inputVisible"
            :maxlength="15"
            :ref="`inputRefList${item.id}`"
            type="text"
            size="small"
            :style="{ width: '78px' }"
            v-model:value="item.tagValue"
            @blur="handleEditFetch(item)"
            @keyup.enter="handleEditFetch(item)"
          />
          <a-tag v-else>
            {{item.item}}
            <EditOutlined class="icon-edit" @click="handleEidt(item)"/>
            <CloseCircleOutlined class="icon-delete" @click="handleDelete(item.id)"/>
          </a-tag>
        </div>
        <a-input
          class="other-tag"
          v-if="inputVisible"
          :maxlength="15"
          ref="inputRef"
          type="text"
          size="small"
          :style="{ width: '78px' }"
          v-model:value="tagValue"
          @blur="handleAdd(index+1)"
          @keyup.enter="handleAdd(index+1)"
        />
        <a-tag class="other-tag" v-else @click="showInput" style="background: #fff; border-style: dashed">
          <PlusOutlined />
          新增
        </a-tag>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { defineComponent } from 'vue'
import { getTagsList, addTagsList, editTagsList, deleteTag } from '@/api/index'
import { PlusOutlined, EditOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { showConfirm } from '@/hooks/common-hooks'
export default defineComponent({
  name: 'TagManager',
  components: {
    PlusOutlined,
    EditOutlined,
    CloseCircleOutlined
  },
  data () {
    return {
      tagList: [],
      activeKey: 0,
      inputVisible: false,
      tagValue: ''
    }
  },
  mounted () {
    this.fetchTagList()
  },
  methods: {
    setData (data) {
      return data.map(item => {
        item.inputVisible = false
        item.tagValue = ''
        return item
      })
    },
    fetchTagList () {
      getTagsList().then((res) => {
        this.tagList = this.setData(res.data)
      })
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.inputRef.focus()
      })
    },
    handleAdd (type) {
      if (this.tagValue.trim().length === 0) {
        this.inputVisible = false
        return false
      }
      addTagsList({
        type,
        item: this.tagValue
      }).then(() => {
        message.success('操作成功')
        this.tagValue = ''
        this.inputVisible = false
        this.fetchTagList()
      })
    },
    handleEidt (item) {
      item.inputVisible = true
      this.$nextTick(() => {
        this.$refs[`inputRefList${item.id}`].focus()
      })
    },
    handleEditFetch (item) {
      if (item.tagValue.trim().length === 0) {
        item.inputVisible = false
        return false
      }
      editTagsList({
        id: item.id,
        item: item.tagValue
      }).then(() => {
        message.success('操作成功')
        this.fetchTagList()
      })
    },
    handleDelete (id) {
      showConfirm({
        content: '确定要删除该标签吗?',
        callBack: () => {
          deleteTag({
            id
          }).then(() => {
            message.success('删除成功')
            this.fetchTagList()
          })
        }
      })
    }
  }
})
</script>

<style lang="scss" scoped>
/deep/ .ant-tag{
  padding: 5px 20px;
  &:hover{
    .icon-edit{
      color: #206CFF;
      cursor: pointer;
    }
  }
}
/deep/ .ant-input{
  padding: 4px 5px;
}
.list{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &>div{
    position: relative;
    margin-right: 20px;
    margin-bottom: 20px;
    align-items: center;
    &:hover{
      .icon-edit{
        opacity: 1;
      }
      .icon-delete{
        display: block;
        cursor: pointer;
      }
    }
  }
  input{
    margin-right: 8px;
  }
  .icon-edit{
    opacity: 0;
    font-size: 16px;
  }
  .icon-delete{
    display: none;
    position: absolute;
    right: 0px;
    top: -5px;
    font-size: 16px;
    color: #206CFF;
  }
}
.my-box{
  padding: 20px;
  min-height: calc(100vh - 220px);
}
.other-tag{
  margin-top: -20px;
}
</style>
